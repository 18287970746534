.category-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 65px;
    height: 80px;    
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.category-item .material-icons,
.category-item p {
    color: #fff;
    text-align: center;
}

.category-item img {
    transition: 0.2s;
}

.category-item:hover img,
.category-item.selected img {
    filter: saturate(15);
}

.category-item:hover .material-icons,
.category-item:hover p,
.category-item.selected p,
.category-item.selected .material-icons {
    color: #ec2e48;

}